// Import dependencies
import React from "react"

// Import components
import TitleSection from "../sections/titleSection"
import {ParagraphText} from "../../styled-components/UILibrary";


// Import styles
import "./our-cause.css"

// Import icons
import NatureConservancy from "../../assets/images/nc.jpg"
import RainforestTrust from "../../assets/images/rt.jpg"
import RainforestActionNework from "../../assets/images/ran.jpg"
import Logo from "../../assets/icons/logo-mobile.svg";

/*
    Our Cause section from the index page
*/
const OurCause = () => (
  <div className="about-container">
  <div className="our-cause__container">
  <ParagraphText
      margin="margin-bottom: 40px"
      separator
      fontSize={25}
  >
  <span style={{fontWeight: "500", color: "#000"}}>What if <span style={{color: "#55BC84"}}>recycling</span> were as easy as online shopping?</span>
  </ParagraphText>
  <p className="about-paragraph">
    Our mission is to make post-consumer waste recycling as convenient, rewarding, and ethical as possible.
  </p>
  <p className="about-paragraph">
    With just a few clicks, you can sell almost anything to us: from old gadgets to used clothes, old textbooks to used coffee grounds — and we’ll make sure it gets recycled properly.
  </p>
  <p className="about-paragraph">
    Think about us when you’re moving house, spring cleaning, or when you’re upgrading to something new.
  </p>

  <p className="about-subtitle">Quick facts</p>
  <div className="quick-facts-container">
    <div  className="quick-facts-item">
      <p>Founded:</p>
      <p>February 26, 2020</p>
    </div>
    <div  className="quick-facts-item">
      <p>Motto:</p>
      <p>Make room for tomorrow</p>
    </div>
    <div  className="quick-facts-item">
      <p>Headquarters:</p>
      <p>Singapore</p>
    </div>
    <div  className="quick-facts-item">
      <p>UEN:</p>
      <p>201829982K</p>
    </div>
  </div>
  </div>
  </div>
)

export default OurCause
