import React from "react";
import { navigate } from "gatsby";

import OurCause from "../components/index/ourCause";
import SectionSeparator from "../components/separators/sectionSeparator";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";
import LogoGreen from "../assets/icons/logo_green_login.svg";
import ArrowForward from "../assets/icons/arrow_forward.svg";

import { PageContainer } from "../styled-components/UILibrary";
import Layout from "../layouts/layout";
import "./about.css";

import OurApproch1 from "../assets/images/our_approch1.png";
import OurApproch2 from "../assets/images/our_approch2.png";
import OurApproch3 from "../assets/images/our_approch3.png";
import OurApproch4 from "../assets/images/our_approch4.png";

import AboutImg1 from "../assets/images/about1.png";
import AboutImg2 from "../assets/images/about2.png";
import AboutImg3 from "../assets/images/about3.png";
import AboutImgBadge from "../assets/images/about_badge.png";

import JoinBackground from "../assets/images/join_background.svg";

const About = () => {
  return (
    <Layout className="about_page_layout">
        <CategoryDrawer/>
      <SEO
        title="About us"
        description="Learn about us and our environmental impact around the world."
      />
      <SearchBar/>
      {/* Page content */}
        {/* Our cause section */}
        <div className="about_header">
          <div className="about_header_background"></div>
          <div className="about_header_nav_shadow"></div>          
          <h1><span style={{color: "#1877F2", display: "block"}}>Empowering people</span> to <span style={{color: "#0AAE67"}}>recycle anything</span> with <span style={{color: "#F9B53E"}}>one click</span></h1>
          <p>We help to create sustainable communities by building the tools that  make recycling as easy as online shopping</p>
        </div>
        <PageContainer className="about_page_container">

          <div className="form_page_container business_use_case_container">
            <div className="case_study_container">
              <span></span>
              <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={AboutImg1} style={{height: "auto", width: 440, left: -40, top: -34}} />
                </div>
                <div className="case_study_item_info">
                  <h2>How it all started</h2>
                  <p><span style={{display: "block", fontStyle: "italic"}}>What if recycling was as easy as online shopping?</span> This simple idea ignited our obsession to develop an interface, user experience, and business model that could help <span style={{color: "#0AAE67"}}>rein in</span> the vast amount of recyclable waste produced in cities every year.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="columns_with_icons columns_with_icons_four">
            <h1>Our approach</h1>
            <div className="columns_with_icons_container">
              <div className="columns_with_icons_flex">
                <div className="columns_with_icons_item">
                  <img src={OurApproch1} />
                  <h3>User-centric</h3>
                  <p>Provide an easy buy-back and disposal experience</p>
                </div>
                <div className="columns_with_icons_item">
                  <img src={OurApproch2} />
                  <h3>Upcycle</h3>
                  <p>Direct reusable items to secondhand outlets</p>
                </div>
                <div className="columns_with_icons_item">
                  <img src={OurApproch3} />
                  <h3>Recycle</h3>
                  <p>Ethically process items that are at their end-of-life stage</p>
                </div>
                <div className="columns_with_icons_item">
                  <img src={OurApproch4} />
                  <h3>Nudge</h3>
                  <p>Incentivize green product purchases via the <span className="columns_with_icons_link" onClick={() => navigate("/shop")}>Ree store</span></p>
                </div>
              </div>
            </div>
          </div>

          
          <div className="form_page_container business_use_case_container">
            <div className="case_study_container">
              <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={AboutImg2} style={{height: "auto", width: 440, left: -11, top: -43}} />
                </div>
                <div className="case_study_item_info">
                  <h2>What we recycle</h2>
                  <p>We aspire to be like Amazon.com, but for recycling — providing instant buy-back and recycling services for a broad (and constantly expanding) range of recyclable products.</p>
                  <div className="how_it_work_links">
                      <span onClick={() => navigate("/galleria/electronics")}>
                        Electronics
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                      <span onClick={() => navigate("/galleria/books")}>
                        Books
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                      <span onClick={() => navigate("/galleria/clothes")}>
                        Clothes
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                      <span>
                        Cookware
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                      <span>
                        Toys
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                    </div>
                </div>
              </div>
              <div className="case_study_item business_use_case_item">
                <div className="business_use_case_img">
                  <img src={AboutImg3} style={{height: "auto", width: 440, left: -35, top: -19}} />
                </div>
                <div className="case_study_item_info">
                  <h2>Responsibilities {"&"} expectations</h2>
                  <p>Our supply chain includes multinational and local partners. Our partners are critical to our commitment to deliver value while operating in a way that respects worker safety, the environment, and human rights.</p>
                  <div className="how_it_work_links">
                      <span onClick={() => navigate("https://help.reinin.sg/topics/vendor-code-of-conduct/")}>
                        Learn more
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z" fill="#1877F2"/>
                        </svg>
                      </span>
                    </div>
                    <img src={AboutImgBadge} height="100" style={{marginTop: 18}}/>
                </div>
              </div>
            </div>
          </div>

          <div className="join_us_container">
            <img src={JoinBackground} />
              <div className="join_us_flex">
                <h3>Join the team</h3>
                <p>Too much talent is spent on building tech that brings out the worst in people. Why not build something that brings out the good, instead?</p>
                <a href="https://www.linkedin.com/company/reininsg" className="about_link">Join us<svg width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M1 9.50021H12.17L7.29 14.3802C6.9 14.7702 6.9 15.4102 7.29 15.8002C7.68 16.1902 8.31 16.1902 8.7 15.8002L15.29 9.21021C15.68 8.82021 15.68 8.19022 15.29 7.80022L8.71 1.20021C8.32 0.810215 7.69 0.810215 7.3 1.20021C6.91 1.59021 6.91 2.22022 7.3 2.61022L12.17 7.50022H1C0.45 7.50022 0 7.95021 0 8.50022C0 9.05021 0.45 9.50021 1 9.50021Z" fill="#1877F2"></path></svg></a>
              </div>
          </div>

        </PageContainer>
    </Layout>
  );
};

export default About;
